import { AxiosError } from 'axios';
import { ValidationError } from 'yup';

export const isAxiosError = (error: any): error is AxiosError<Partial<any>> =>
  error.isAxiosError === true;

export const isYupError = (error: any): error is ValidationError =>
  error.name === 'ValidationError' && error.inner instanceof Array;

export const isGenericError = (error: any): error is Error => typeof error.message !== 'undefined';

export function parseError(error: any): string {
  if (error?.response?.data?.messages) {
    error.response.data.messages.map((e: any) => e).join(', ');
  }
  if (isAxiosError(error)) {
    return (
      error.response?.data?.message ||
      (error?.response?.data?.messages?.map((e: any) => e).join(', ') as string) ||
      'Something went wrong'
    );
  }
  if (isYupError(error)) {
    return error.inner.map((e) => e.message).join(', ');
  }
  if (isGenericError(error)) {
    return error.message;
  }
  return 'Something went wrong';
}
