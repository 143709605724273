import localforage from 'localforage';
import { getDateRangeWithLabel } from './dateRange';

/**
 * This can be used to format amount in Naira properly
 * @example
 * currency.format(parseFloat('14304736.75')) // output ₦14,304,736.75
 */

export const currency = (curr: TCurrencyArgs | TCurrency = 'NGN', minimumFractionDigits = 2) =>
  new Intl.NumberFormat(`en-${curr === 'NGN' ? 'NG' : 'US'}`, {
    style: 'currency',
    currency: curr,
    minimumFractionDigits,
    currencyDisplay: 'symbol'
  });

export type TCurrencyArgs = 'NGN' | 'USD' | 'GHA' | 'ZAR';

export { default as useLocalStorage } from './useLocalStorage';
export { default as RSAEncrypt } from './RSA';

export function capitalizeFirstLetter(string: string) {
  const words = string.toLowerCase().split(/\s+/);
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
}

export const ISSERVER = typeof window === 'undefined';

export const lf = localforage.createInstance({
  description: 'Powered by Bamboo',
  name: 'PBB',
  storeName: 'PBB-CONFIGS',
  version: 1.0
});

export const Sandbox = {
  toggle(checked: boolean) {
    localStorage.setItem('pbb-tm', checked ? 'true' : 'false');
  },
  check: () => (!ISSERVER ? localStorage.getItem('pbb-tm') === 'true' : null)
};

export { getDateRangeWithLabel };
