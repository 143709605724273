import { TENANT } from 'constants/api';
import client from 'requests';

export interface IStatReposnse {
  deposit: string;
  withdrawal: string;
  trade: string;
  revenue: string;
}
const call = () => client.get<IStatReposnse>(TENANT.GET_STAT);

export default call;
