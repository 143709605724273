export const Page = {
  dashboard: '/dashboard',
  trades: '/trades',
  deposits: '/deposit',
  withdrawals: '/withdrawals',
  users: '/users',
  Auth: {
    login: '/auth/login'
  },
  stocks: '/stocks',
  tickets: '/tickets'
};
