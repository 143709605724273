import BOLink from 'components/BOLink';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { Page } from 'constants/route';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useStore } from 'store';
import { Sandbox } from 'utils';

const SandboxBanner = () => {
  const { AuthStore: Auth } = useStore();
  const route = useRouter();

  const switchToLive = () => {
    Sandbox.toggle(false);
    route.reload();
    Auth.logout();
  };

  if (!Auth.isSandbox) {
    return <div />;
  }
  return (
    <MDBox
      sx={{
        display: 'flex',
        fontSize: '1rem !important',
        position: 'sticky',
        color: 'white!important',
        top: 0,
        flexFlow: { xs: 'column', md: 'row' },
        justifyContent: 'center',
        alignItems: 'center',
        '& a': {
          fontWeight: 'bold',
          textDecoration: 'underline!important'
        }
      }}
      variant="gradient"
      bgColor="error"
    >
      <MDTypography textAlign="center" color="white">
        You are currently running in Test Mode (Sandbox)
      </MDTypography>
      <BOLink nextHref={Page.Auth.login} onClick={switchToLive} ml={{ xs: 0, md: '2rem' }}>
        Swith to Live Mode
      </BOLink>
    </MDBox>
  );
};

export default observer(SandboxBanner);
