import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import typography from 'assets/theme/base/typography';

function Footer() {
  const { size } = typography;

  return (
    <MDBox position="absolute" width="100%" bottom={0} py={4}>
      <Container>
        <MDBox
          width="100%"
          display="flex"
          justifyContent={{ xs: 'center', lg: 'flex-end' }}
          alignItems="center"
          px={1.5}
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color="text"
            fontSize={size.sm}
          >
            <Link href="https://investbamboo.com/" target="_blank">
              <MDTypography variant="button" fontWeight="medium" color="text">
                &nbsp;Powered By Bamboo&nbsp;&copy; {new Date().getFullYear()}
              </MDTypography>
            </Link>
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
}

export default Footer;
