import { forwardRef } from 'react';

// Custom styles for MDTypography
import MDTypographyRoot from 'components/MDTypography/MDTypographyRoot';

// Material Dashboard 2 React contexts
import { useMaterialUIController } from 'context';
import { TColor, TVerticalAlign } from 'types/mui-theme';
import { TypographyProps } from '@mui/material/Typography';

const MDTypography = forwardRef<any, IMDTypographyProps & { component?: React.ElementType<any> }>(
  (
    { color, fontWeight, textTransform, verticalAlign, textGradient, opacity, children, ...rest },
    ref
  ) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
      <MDTypographyRoot
        {...rest}
        ref={ref}
        ownerState={{
          color,
          textTransform,
          verticalAlign,
          fontWeight,
          opacity,
          textGradient,
          darkMode
        }}
      >
        {children}
      </MDTypographyRoot>
    );
  }
);
MDTypography.displayName = 'MDTypography';
// Setting default values for the props of MDTypography
MDTypography.defaultProps = {
  color: 'dark',
  fontWeight: '',
  textTransform: 'none',
  verticalAlign: 'unset',
  textGradient: false,
  opacity: 1,
  component: undefined
};
interface IMDTypographyProps extends TypographyProps {
  color?: TColor;
  fontWeight?: 'light' | 'regular' | 'medium' | 'bold' | 'semibold' | '';
  textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase';
  verticalAlign?: TVerticalAlign;
  textGradient?: boolean;
  children: React.ReactNode;
  opacity?: number;
}

export default MDTypography;
