import { AUTH } from 'constants/api';
import client from '..';

interface ILogoutResponse {
  accessToken: string;
}

const call = () => client.get<ILogoutResponse>(AUTH.LOG_OUT);

export default call;
