import { AUTH } from 'constants/api';
import client from '..';

export interface ITenantDetailsResponse {
  email: string;
  scopes: string[];
  api_consumer_profile: {
    name: string;
  };
  api_consumer: {
    id: string;
    app_key: string;
    password: string;
    username: string;
    inserted_at: string;
    updated_at: string;
    client_id: string;
    type: string;
    status: string;
  };
}

const call = () => client.get<ITenantDetailsResponse>(AUTH.TENANT_DETAILS);

export default call;
