import { AxiosError } from 'axios';
import { makeObservable, observable, action, computed, flow, ObservableMap } from 'mobx';
import fetchUser, {
  getUsersCount,
  IUser,
  IUsersStatResponse,
  getAllUsersStat,
  IUserActivities,
  getUserActivities
} from 'requests/users';
import { parseDate } from 'utils/parseDate';

export const userKeyToLabelMap = {
  inserted_at: 'Created at',
  id: 'User ID',
  full_name: 'Full Name',
  phone_number: 'Phone Number',
  email: 'Email',
  user_holdings: 'Portfolio',
  status: 'Status',
  updated_at: 'Updated at',
  verified: 'Verified',
  funded_account: 'Account Funded',
  date_of_birth: 'DOB',
  address: 'Address'
};
export interface ITableformattedUser {
  id: string;
  phone_number: string;
  verified: boolean;
  inserted_at: string;
  updated_at: string;
  funded_account: boolean;
  user_holdings: Omit<IStockHolding, 'logo_url'>[];
  email: string; // > users_basic_info.email
  date_of_birth: string; // > identity_document?.date_of_birth || users_basic_info.date_of_birth
  full_name: string; // > identity_document?.full_name || users_basic_info.name & users_basic_info.surname
  address: string;
  status: 'active' | 'inactive';
}

export class UserStoreImpl {
  users: IUser[] = [];

  usersStat: IUsersStatResponse = {
    active_users: 0,
    inactive_users: 0,
    users_count: 0
  };

  userActivities: IUserActivities = {
    user_deposit: [],
    user_trades: [],
    user_withdrawals: []
  };

  isLoading: {
    usersStat: boolean;
    activities: boolean;
  } = {
    usersStat: false,
    activities: false
  };

  usersCount: number = 0;

  fetchingUserCount: boolean = false;

  loading = {
    fetchUser: false
  };

  error: Partial<Error> = {};

  signals = new ObservableMap<keyof typeof this.loading, AbortController>();

  search = '';

  meta: Partial<IPaginatedMeta> = {
    currentPage: 0,
    itemCount: 0
  };

  constructor() {
    makeObservable(this, {
      users: observable,
      loading: observable,
      error: observable,
      meta: observable,
      usersCount: observable,
      fetchingUserCount: observable,
      usersStat: observable,
      isLoading: observable,
      userActivities: observable,
      search: observable,

      fetchUser: flow.bound,
      getUserCount: flow.bound,
      fetchUserStat: flow.bound,
      fetchUserActivities: flow.bound,
      setSearch: action.bound,
      setReqError: action.bound,
      getUserView: computed,
      getUserActivities: computed
    });
  }

  setReqError(err: AxiosError) {
    if (err.response?.status !== 401) {
      this.error = err;
      setTimeout(() => {
        this.error = {};
      }, 10000);
    }
  }

  setSearch(search: string) {
    this.search = search;
  }

  *fetchUser(params: Partial<IPaginationConfig> = {}, controller?: AbortController) {
    if (this.loading.fetchUser) {
      this.signals.get('fetchUser')?.abort();
      if (controller) this.signals.set('fetchUser', controller);
    }
    this.loading.fetchUser = true;
    try {
      const { data } = yield fetchUser(params, controller?.signal);
      this.users = data.items;
      this.meta = data.meta;
    } catch (error) {
      this.setReqError(error);
    } finally {
      this.loading.fetchUser = false;
    }
  }

  *fetchUserActivities(userId: string) {
    this.isLoading.activities = true;
    try {
      const { data } = yield getUserActivities(userId);
      this.userActivities = data;
    } catch (error) {
      this.setReqError(error);
    } finally {
      this.isLoading.activities = false;
    }
  }

  *getUserCount() {
    this.fetchingUserCount = true;
    try {
      const { data } = yield getUsersCount();
      this.usersCount = data;
    } catch (error) {
      this.setReqError(error);
    } finally {
      this.fetchingUserCount = false;
    }
  }

  *fetchUserStat() {
    this.isLoading.usersStat = true;
    try {
      const { data } = (yield getAllUsersStat()) as { data: IUsersStatResponse };
      this.usersStat = data;
    } catch (error) {
      this.setReqError(error);
    } finally {
      this.isLoading.usersStat = false;
    }
  }

  get getUserView() {
    return this.users.map<ITableformattedUser>((user) => ({
      id: user.id,
      phone_number: user.phone_number,
      address: user.identity_document?.address || '',
      date_of_birth: user.identity_document?.date_of_birth
        ? parseDate(user.identity_document.date_of_birth)
        : `${user.users_basic_info.date_of_birth}`,
      email: user.users_basic_info.email,
      full_name:
        user.identity_document?.full_name ||
        `${user.users_basic_info.name} ${user.users_basic_info.surname}`,
      funded_account: user.funded_account,
      inserted_at: user.inserted_at,
      updated_at: user.updated_at,
      user_holdings: user.user_holdings,
      verified: user.verified || Boolean(user.identity_document),
      status: user.identity_document && user.funded_account ? 'active' : 'inactive'
    }));
  }

  get getUserActivities() {
    return {
      deposits: this.userActivities.user_deposit.map<ITableFormattedDeposit>((deposit) => ({
        id: deposit.id,
        full_name: deposit.user?.identity_document?.full_name || 'Not available',
        deposit_type: deposit.deposit_type,
        dollar_amount: deposit.dollar_amount,
        dollar_instant_deposit_fee: deposit.dollar_instant_deposit_fee,
        dollar_processing_fee: deposit.dollar_processing_fee,
        inserted_at: deposit.inserted_at,
        reference: deposit.reference,
        amount_paid: deposit.amount_paid,
        status: deposit.status.match(/Settlemented/i)
          ? 'settled'
          : deposit.status.match(/Deleted/i)
          ? 'cancelled'
          : deposit.status
      })),
      trades: this.userActivities.user_trades.map<ITableFormattedTrades>((trade) => ({
        id: trade.id,
        dollar_fee: trade.dollar_fee,
        full_name: trade.user?.identity_document?.full_name || 'Not Available',
        inserted_at: trade.inserted_at,
        price_per_share: trade.price_per_share,
        side: trade.side,
        status: trade.status,
        stock_symbol: trade.stock_symbol,
        transaction_value: trade.transaction_value,
        user_currency_fee: trade.user_currency_fee,
        user_currency_price_per_share: trade.user_currency_price_per_share,
        user_currency_transaction_value: trade.user_currency_transaction_value,
        dw_order_id: trade.dw_order_id
      })),
      withdrawals: this.userActivities.user_withdrawals.map<ITableFormattedWithdrawal>(
        (withdrawal) => ({
          id: withdrawal.id,
          inserted_at: withdrawal.inserted_at,
          account_number: withdrawal.account_number,
          additional_instructions: withdrawal.additional_instructions,
          amount: withdrawal.amount,
          bank_name: withdrawal.bank_name,
          currency: withdrawal.currency,
          exchange_rate_value: withdrawal.exchange_rate_value,
          fees: withdrawal.fees,
          full_name: withdrawal.user?.identity_document?.full_name || 'Not available',
          status: withdrawal.status,
          updated_at: withdrawal.updated_at
        })
      )
    };
  }
}

const UserStore = new UserStoreImpl();

export default UserStore;
