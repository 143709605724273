import type { AppProps } from 'next/app';
import Head from 'next/head';
import { PBBambooUIControllerProvider } from 'context';
import AppWrapper, { type TLayoutOptions } from 'AppWrapper';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { StoreProvider } from 'store';
import { NextPage } from 'next';
import { useEffect, useMemo } from 'react';

export type NextPageWithLayout<T = {}> = NextPage<T> & {
  useLayout?: TLayoutOptions;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

NProgress.configure({
  showSpinner: true
});

function handleRouteChange() {
  NProgress.start();
}

function handleRouteChanged() {
  NProgress.done();
}

function MyApp({ Component, pageProps, router }: AppPropsWithLayout) {
  const layoutOptions = useMemo(() => Component.useLayout ?? 'app', [Component.useLayout]);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChanged);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChanged);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <title>PBB Admin - Dashboard</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,user-scalable=no"
        />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Buy and sell hundreds of Nigerian and U.S. stocks, right from your mobile phone or computer"
        />
        <link rel="apple-touch-icon" href="/logo192.png" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      </Head>
      <PBBambooUIControllerProvider>
        <StoreProvider>
          <AppWrapper layout={layoutOptions}>
            <Component {...pageProps} />
          </AppWrapper>
        </StoreProvider>
      </PBBambooUIControllerProvider>
    </>
  );
}

export default MyApp;
