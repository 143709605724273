import axios, { AxiosError } from 'axios';
import router from 'next/router';
import AuthStore from 'store/AuthStore';
import AppConfig from 'app-config';
import { ISSERVER, Sandbox } from 'utils';
import { Page } from 'constants/route';
import { getXSp } from 'utils/RSA';

const client = axios.create({
  baseURL: Sandbox.check() ? AppConfig.SandboxApiBaseUrl : AppConfig.ApiBaseUrl,
  headers: {
    'x-subject-type': 'standard',
    'content-type': 'application/json'
  },
  withCredentials: true
});

client.interceptors.request.use((config) => {
  const modifiedConfig = { ...config };
  const { ttl, admin_token } = AuthStore.isAuthenticated();
  const treshold = 5 * 60 * 1000;
  if (AuthStore.isLoading.refresh === false && ttl < treshold && ttl > 0) {
    AuthStore.refreshToken();
  }

  if (admin_token && modifiedConfig.headers)
    modifiedConfig.headers.Authorization = `Bearer ${admin_token}`;

  return modifiedConfig;
});

const NO_REDIRECT_ROUTES = [Page.Auth.login] as string[];
const EXIT_HTTP_CODE = 401;

client.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (
      !NO_REDIRECT_ROUTES.includes(router.pathname) &&
      error.response?.status === EXIT_HTTP_CODE
    ) {
      localStorage.setItem('fromPath', router.pathname);
      AuthStore.logout();
      return Promise.reject(new Error('Unauthorized'));
    }
    return Promise.reject(error);
  }
);

export default client;

export const serverFetch = axios.create({
  baseURL: AppConfig.ApiBaseUrl,
  headers: {
    'x-subject-type': 'standard',
    'content-type': 'application/json'
  }
});

export const authMS = axios.create({
  baseURL: AppConfig.AuthMSBaseUrl,
  headers: {
    'x-subject-type': 'standard',
    'content-type': 'application/json'
  },
  withCredentials: true
});

authMS.interceptors.request.use((config) => {
  const modifiedConfig = { ...config };
  const { ttl, admin_token, email } = AuthStore.isAuthenticated();
  const treshold = 5 * 60 * 1000;
  if (AuthStore.isLoading.refresh === false && ttl < treshold && ttl > 0) {
    AuthStore.refreshToken();
  }

  if (modifiedConfig.headers) {
    if (!modifiedConfig.headers['x-sp']) modifiedConfig.headers['x-sp'] = getXSp(email);
    if (admin_token) modifiedConfig.headers.Authorization = `Bearer ${admin_token}`;
  }

  return modifiedConfig;
});

authMS.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (
      !ISSERVER &&
      !NO_REDIRECT_ROUTES.includes(router.pathname) &&
      error.response?.status === EXIT_HTTP_CODE
    ) {
      localStorage.setItem('fromPath', router.pathname);
      AuthStore.logout();
      return Promise.reject(new Error('Unauthorized'));
    }
    return Promise.reject(error);
  }
);
