const TENANT = {
  GET_DEPOSITS: '/deposits',
  GET_DEPOSIT_SUMMARY_STAT: '/deposits/sum',
  GET_WITHDRAWALS: '/withdrawals',
  GET_WITHDRAWAL_SUMMARY_STAT: '/withdrawals/sum',
  GET_TRADES: '/trades',
  GET_TRADE_SUMMARY_STAT: '/trades/sum',
  GET_LIFE_TIME_TRADE_SUM: '/trades/life_time_trades_sum',
  GET_STAT: '/stats/life_time_values',
  GET_STOCKS: '/stocks'
} as const;

const USER = {
  GET_USERS: '/users',
  GET_USER_STAT: '/users/stat',
  GET_USER_COUNT: '/users/number_of_users',
  GET_USER_ACTIVITIES: '/users/user/:user_id',
  DOWNLOAD_CSV: '/:record/download_csv/'
} as const;

const AUTH = {
  LOG_OUT: '/auth/sign_out',
  TENANT_DETAILS: '/auth/admin'
} as const;

const AUTH_MS = {
  CHECK_EMAIL: '/auth/check_email/:email',
  SIGNIN_PASSWORD: '/auth/sign_in',
  VERIFY_SIGNUP_LINK: '/auth/verify_sign_up',
  SET_PASSWORD: '/auth/create_sign_up_password',
  VERIFY_OTP: '/auth/verify_otp',
  REFRESH_TOKEN: '/auth/refresh_token',
  RESET_PASSWORD: 'auth/request_reset_password/:email',
  CHANGE_PASSWORD: '/auth/change_my_password'
} as const;

export { AUTH_MS, AUTH, USER, TENANT };
