import { TENANT } from 'constants/api';
import { rangeData } from 'constants/dateRange';
import client from 'requests';
import { TCurrencyArgs } from 'utils';

const range = rangeData.find((item) => item.label === 'Last 7 Days');

const defaultParams = {
  page: 1,
  limit: 25,
  start_date: range.startDate.valueOf(),
  end_date: range.endDate.valueOf(),
  q: '',
  txn_status: ''
};

const fetchDeposit = (params: Partial<IPaginationConfig> = {}, signal?: AbortSignal) =>
  client.get<IPaginatedEndpointResp<IDeposit>>(TENANT.GET_DEPOSITS, {
    params: { ...defaultParams, ...params },
    signal
  });

export default fetchDeposit;

export const fetchSummaryStat = (params: Omit<IPaginationConfig, 'page' | 'limit'> = {}) =>
  client.get<IDepositSummaryStat>(TENANT.GET_DEPOSIT_SUMMARY_STAT, {
    params: { ...defaultParams, ...params }
  });

export interface IDepositSummaryStat {
  range: {
    start_date: number;
    end_date: number;
  };
  stat: 'days' | 'weeks' | 'months';
  data: {
    id: number;
    sum: string | null;
  }[];
}

export interface IDeposit {
  id: string;
  status: string;
  inserted_at: string;
  dollar_amount: string;
  amount_paid: string;
  deposit_type: string;
  dollar_instant_deposit_fee: string;
  dollar_processing_fee: string;
  reference: string;
  user_id: string;
  user: {
    id: string;
    identity_document: {
      id: string;
      full_name: string;
      user_id: number;
    };
  };
  currency?: TCurrencyArgs;
  updated_at: string;
}
