import React, { createContext, useContext } from 'react';
import DashboardStore, { DashboardImpl } from './Dashboard';
import DepositStore, { DepositStoreImpl } from './DepositStore';
import AuthStore, { AuthStoreImpl } from './AuthStore';
import TradeStore, { TradeStoreImpl } from './TradesHistoryStore';
import UserStore, { UserStoreImpl } from './UserStore';
import WithdrawalStore, { WithdrawalStoreImpl } from './WithdrawalStore';
import StocksStore, { StockStoreImpl } from './StocksStore';

interface BambooStoreContext {
  AuthStore: AuthStoreImpl;
  UserStore: UserStoreImpl;
  TradeStore: TradeStoreImpl;
  WithdrawalStore: WithdrawalStoreImpl;
  DepositStore: DepositStoreImpl;
  DashboardStore: DashboardImpl;
  StocksStore: StockStoreImpl;
}

const StoreContext = createContext<BambooStoreContext>({} as any);

interface StoreProviderProps {
  children: React.ReactNode;
}

const stores = {
  AuthStore,
  UserStore,
  TradeStore,
  WithdrawalStore,
  DepositStore,
  DashboardStore,
  StocksStore
};

export const StoreProvider = ({ children }: StoreProviderProps) => (
  <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>
);

export const useStore = () => useContext(StoreContext);

export default StoreProvider;
