import { TENANT } from 'constants/api';
import { rangeData } from 'constants/dateRange';
import client from 'requests';

const range = rangeData.find((item) => item.label === 'Last 7 Days');

const defaultParams = {
  page: 1,
  limit: 25,
  start_date: range.startDate.valueOf(),
  end_date: range.endDate.valueOf(),
  q: '',
  txn_status: ''
};

const fetchWithdrawal = (params: Partial<IPaginationConfig> = {}, signal?: AbortSignal) =>
  client.get<IPaginatedEndpointResp<IWithdrawal>>(TENANT.GET_WITHDRAWALS, {
    params: { ...defaultParams, ...params },
    signal
  });

export default fetchWithdrawal;

export const fetchSummaryStat = (params: Omit<IPaginationConfig, 'page' | 'limit'> = {}) =>
  client.get<IWithdrawalSummaryStat>(TENANT.GET_WITHDRAWAL_SUMMARY_STAT, {
    params: { ...defaultParams, ...params }
  });

export interface IWithdrawalSummaryStat {
  range: {
    start_date: number;
    end_date: number;
  };
  stat: 'days' | 'weeks' | 'months';
  data: {
    id: number;
    sum: number;
  }[];
}

export interface IWithdrawal {
  id: string;
  account_number: string;
  additional_instructions: string | null;
  amount: string;
  bank_name: string;
  currency: string;
  fees: string;
  status: string;
  updated_at: string;
  exchange_rate_value: string;
  inserted_at: string;
  user_id: string;
  user?: IUserShortDetails;
  reference: string;

  // Considered for removal
  intermediary_bank?: null;
  intermediary_bank_swift_code?: null;
  swift_aba_code?: null;
  api_consumer_id?: 1;
  bank_zip_code?: null;
  beneficiary_name?: null;
  bank_state?: null;
  bank_address?: string | null;
  bank_code?: string;
  bank_country?: null;
  bank_fw_id?: string;
}
