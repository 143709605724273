import { USER } from 'constants/api';
import { rangeData } from 'constants/dateRange';
import addMonths from 'date-fns/addMonths';
import { IDeposit } from 'requests/deposit';
import { ITradesHistory } from 'requests/trades';
import { IWithdrawal } from 'requests/witdrawals';
import client from '..';

export interface IUser {
  id: string;
  phone_number: string;
  verified: boolean;
  inserted_at: string;
  updated_at: string;
  funded_account: boolean;
  user_holdings: Omit<IStockHolding, 'logo_url'>[];
  users_basic_info: IUserBasic;
  identity_document: IIdentityDocument;
}

export interface IUsersStatResponse {
  users_count: number;
  active_users: number;
  inactive_users: number;
}

export interface IUserActivities {
  user_trades: ITradesHistory[];
  user_deposit: IDeposit[];
  user_withdrawals: IWithdrawal[];
}

const range = rangeData.find((item) => item.label === 'Last 7 Days');

const defaultParams = {
  page: 1,
  limit: 25,
  active_users: true,
  start_date: range.startDate.valueOf(),
  end_date: range.endDate.valueOf(),
  q: ''
};

const fetchUser = (params: Partial<IPaginationConfig> = {}, signal?: AbortSignal) =>
  client.get<IPaginatedEndpointResp<IUser>>(USER.GET_USERS, {
    params: { ...defaultParams, ...params },
    signal
  });

export default fetchUser;

const defaultValues = {
  domain: 'user',
  active_users: true,
  start_date: addMonths(new Date(), -1).valueOf(),
  end_date: new Date().valueOf(),
  q: ''
};

const download_csv = (
  domain: TRecordTableDomain,
  params: Omit<IPaginationConfig, 'limit' | 'page'> = {}
) =>
  client.get(USER.DOWNLOAD_CSV.replace(':record', domain), {
    params: {
      ...defaultValues,
      ...params,

      responseType: 'blob'
    }
  });

const getUsersCount = () => client.get<number>(USER.GET_USER_COUNT);

const getAllUsersStat = () => client.get<IUsersStatResponse>(USER.GET_USER_STAT);

const getUserActivities = (user_id: string) =>
  client.get<IUserActivities>(USER.GET_USER_ACTIVITIES.replace(':user_id', user_id));

export { download_csv, getUsersCount, getAllUsersStat, getUserActivities };
