import parse from 'date-fns/parse';

const formateDate = (date) => parse(date, 'dd-MM-yyyy', new Date()).toISOString()
export const parseDate = (date: string) => {
  const validDate = /^([0-9]{1,2})-([0-9]{1,2})-([0-9]{4})$/;
  const isValid = date.match(validDate);
  if(isValid) {
    return formateDate(date);
  }
  const restructuredDate = date.split('-').reverse().join('-');
  return formateDate(restructuredDate);
}
  