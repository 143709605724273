import { PropsWithChildren } from 'react';
import SandboxBanner from 'components/BOSandboxBanner';
import { Card } from '@mui/material';
import PBBLogo from 'assets/svg/PBBLogo';
import MDBox from 'components/MDBox';
import sidenavLogoLabel from 'examples/Sidenav/styles/sidenav';
import BasicLayout from '..';

const AuthLayout = ({ children }: PropsWithChildren<{}>) => (
  <>
    <SandboxBanner />
    <BasicLayout>
      <Card
        sx={{
          bgcolor: '#fff',
          '& label': {
            color: '#7b809a',
            fontWeight: 800
          },
          '& input': {
            color: '#343a40'
          }
        }}
      >
        <MDBox borderRadius="lg" mx={2} p={1} mb={1}>
          <MDBox sx={(theme) => sidenavLogoLabel(theme, { miniSidenav: false }, '#0fdba880')}>
            <PBBLogo />
          </MDBox>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {children}
        </MDBox>
      </Card>
    </BasicLayout>
    ;
  </>
);

export default AuthLayout;
