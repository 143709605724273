import { TENANT } from 'constants/api';
import client from 'requests';

const getStocks = (
  { page = 0, limit = 25, q, is_active }: IPaginationConfig,
  signal?: AbortSignal
) =>
  client.get<IPaginatedEndpointResp<IStock>>(TENANT.GET_STOCKS, {
    params: {
      page,
      limit,
      q,
      is_active
    },
    signal
  });

export { getStocks };
